.custom-tabs.nav.nav-tabs {
    border-bottom: none !important;
}

.custom-tabs .nav-item {
    border: none;
}

.custom-tabs .nav-link {
    border: none;
    background-color: transparent;
    color: #000;
    font-weight: normal;
    padding: 5px 10px;
    font-size: 14px;
}

.custom-tabs .nav-link.active {
    border-bottom: 2px solid #e74c3c !important;
    background-color: transparent !important;
    font-weight: bold;
    color: #e74c3c;
}