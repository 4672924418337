// $secondary: red;

.phoneNumberField input {
  border: none;
  &:focus-visible {
    outline: none !important;
  }

  .PhoneInputCountrySelect svg {
    display: none;
  }
}
.input-group .form-control:focus {
  box-shadow: none !important;
}

.form-control .is-invalid {
  border-radius: 0px 0.5rem 0.5rem 0px;
}

.signUp_Form {
  .invalid-feedback {
    display: block !important;
  }
}

.signUp_Form.model {
  height: 54vh;
  overflow-y: scroll;
}

.fc.fc-media-screen.fc-theme-bootstrap {
  height: 1300px;
}

.dot_declined {
  height: 12px;
  margin-left: 5px;
  width: 12px;
  background-color: rgb(200, 40, 40);
  border-radius: 50%;
  display: inline-block;
}

.dot_pending {
  height: 12px;
  margin-left: 5px;
  width: 12px;
  background-color: rgb(202, 202, 10);
  border-radius: 50%;
  display: inline-block;
}

.dot_approved {
  height: 12px;
  margin-left: 5px;
  width: 12px;
  background-color: rgb(42, 149, 42);
  border-radius: 50%;
  display: inline-block;
}

.dot_withdrawn {
  height: 12px;
  margin-left: 5px;
  width: 12px;
  background-color: rgb(169, 169, 169);
  border-radius: 50%;
  display: inline-block;
}

.dotSpan {
  display: flex;
  align-items: center;
}

.table-responsive.py-4 {
  width: 100% !important;
}

.table-responsive.py-4 .react-bootstrap-table {
  overflow: auto !important;
  width: 100% !important;
}

// for refresh icon in project listing
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(-360deg);
  }
}

.animate-spin {
  animation: spin 1s linear infinite;
}

.required-info {
  color: grey;
  font-style: italic;
  font-size: small;
}

.table-row {
  height: 60px;
}
.noNumberSpinner::-webkit-inner-spin-button,
.noNumberSpinner::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
