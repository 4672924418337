/* Add this CSS in your stylesheet */
.holiday-day {
  position: relative;
  /* Set position to relative for the container */
  color: red;
  /* Set the color for holiday dates */
}

.holiday-tooltip {
  display: none;
  /* Hide the tooltip by default */
  position: absolute;
  background-color: #333;
  color: white;
  padding: 3px 6px;
  border-radius: 4px;
  font-size: 12px;
  top: -30px;
  /* Adjust the distance from the date */
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
  z-index: 9999;
}

/* Show the tooltip when hovering over the holiday-day element */
.holiday-day:hover .holiday-tooltip {
  display: block;
}

/* Style for regular (non-holiday, non-weekend) days */
.rdtDay:not(.holiday-day):not(.disabled) {
  color: black;
  /* Set the text color to black */
  /* Add any other styles for regular days here */
}

.disabled {
  /* Add your styles for disabled elements here */
  color: grey;
  /* Example: Set the text color to grey */
  /* Any other styles you want to apply */
}

.custom-modal-body {
  min-height: 520px;

}

.scrollable-table {
  max-height: 300px;
  /* Adjust the maximum height as needed */
  overflow-y: auto;
}

.holiday-modal {
  min-width: 600px !important;
}

.bold-today {
  font-weight: bold;
  color: black;
}