.custom-dropdown {
    background: none;
    border: none;
    padding: 5px 2px;
}

.icon-container {
    display: flex;
    justify-content: space-between;
    margin-top: 4px;
}

.grade-utilization {
    display: inline-block;
    border: 2px solid #fff;
    background-color: #fff;
    border-radius: 50%;
    margin-right: 12px;
    font-weight: bolder;
    color: #FF5151
}

.grade {
    display: inline-block;
    border: 2px solid #fff;
    background-color: #ffffff;
    border-radius: 50%;
    margin-right: 12px;
    font-weight: bolder;
    color: #c7b304;
}

.css-1s2u09g-control {
    border-color: black;
}


.order-card-blue {
    background-color: #E8EFFF;

}

.order-card-red {
    background-color: #FFE0E0;
}

.order-card-green {
    background-color: #E4FFE0;
}

.order-card-yellow {
    background-color: #FFFCE0;
}

.card-title {
    color: grey;
    font-size: 14px;
    margin-left: 8px;
}

.card-figure {
    font-weight: bolder !important;
    font-size: 20px;
}

.card-foot {
    margin-left: 8px;
    font-size: 10px;
}

.custom-dropdown-container {
    width: 180px;
}

.date-range {
    display: flex;
    justify-content: space-between;
    width: 200px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f0f0f0;
    font-family: Arial, sans-serif;
}

.date-interval-container {
    width: 230px;
    font-weight: bold;
    color: #333;
    margin-left: 18px;
}

.date-interval-container p {
    display: inline;
    color: #e64425;
}
