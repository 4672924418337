.custom-dropdown {
  background: none;
  border: none;
  padding: 5px 2px;
}

.icon-container {
  display: flex;
  justify-content: space-between;
  margin-top: 4px;
}

.grade-utilization {
  display: inline-block;

  border: 2px solid #fff;
  background-color: #fff;
  border-radius: 50%;
  margin-right: 12px;
  font-weight: bolder;
  color: #ff5151;
}

.grade-activity {
  display: inline-block;
  border: 2px solid #fff;
  background-color: #fff;
  border-radius: 50%;
  margin-right: 12px;
  font-weight: bolder;
  color: #17aa00;
}

.calendar-container {
  position: absolute;
  top: 100%;
  z-index: 9999;
}

.order-card-blue {
  background-color: #e8efff;
}

.order-card-red {
  background-color: #ffe0e0;
}

.order-card-green {
  background-color: #e4ffe0;
}

.order-card-yellow {
  background-color: #fffce0;
}

.card-title {
  color: grey;
  font-size: 12px;
  margin-left: 8px;
}

.card-foot {
  margin-left: 8px;
  font-size: 10px;
}

.custom-dropdown-container {
  width: 180px;
}

.date-range {
  display: flex;
  justify-content: space-between;
  width: 200px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f0f0f0;
  font-family: Arial, sans-serif;
}

.date-interval-container {
  width: 230px;
  font-weight: bold;
  color: #333;
  margin-left: 18px;
}

.date-interval-container p {
  display: inline;
  color: #e64425;
}
.filter-button {
  margin-top: 26px;
}
.reset-filter-button {
  padding-top: 8px;
  padding-bottom: 9px;
}
