.leavesTable {
    border-collapse: collapse;
    width: 100%;
  }
  
  .leavesTable th,
  .leavesTable td {
    border: 1px solid black;
    padding: 8px;
    text-align: center;
  }
  
  .leavesTable tbody tr:nth-child(even) {
    background-color: #f2f2f2;
  }
  
  .leavesTable thead th {
    background-color: #374151;
    color: white;
    font-size: 16px;
  }
  
  .typeHeading {
    font-size: 20px;
  }
  
  .verticalHeader {
    text-align: left;
    font-weight: bold;
  }
  