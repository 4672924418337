.chart-container {
  width: 100%;
  max-width: 570px;
  /* Adjust the maximum width as per your needs */
  margin: 0 auto;
  /* Centers the chart horizontally */
}

.line-chart-container {
  width: 100%;
  height: 100%;
}

/* Additional CSS can be added to style the chart as needed */