.report-container {
    display: flex;
}

.report {
    flex: 75%;
}



.pie-chat {
    flex: 35%;
}
.custom-dropdown-container {
    width: 180px; 
  }