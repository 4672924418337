/* Table.css */
.table thead th {
    background-color: #333C4E;
    color: white;
}



.table-title {
    font-weight: bold;
    margin-bottom: 10px;
}

.table-box {
    border: 1px solid #ddd;
    padding: 10px;
}

.custom-table {
    border-collapse: separate;
    border-spacing: 0;
}

.custom-table th,
.custom-table td {
    border-right: 1px solid #9ca3af4f;
    padding: 8px;
}

.custom-pagination-container {
    display: flex;
    justify-content: center;
    margin-top: 4px;
    border-radius: 0;
}

.pagination {
    margin: 0;
}

.custom-pagination-container .pagination-item {
    display: inline-block;
    padding: 2px;
    border: 1px solid #ccc;
    border-radius: 0px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px;
}

.badge {
    font-size: 12px;
}

.load-more-button {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 2px;
    background-color: transparent;
    color: #333C4E;
    border: none;
    border-radius: 0px;
    margin-top: 0px;
    transition: color 0.2s ease-in-out;
}